@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Stencil+Text:wght@600&display=swap');

* {
    // text-align: center;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

h1,h2,h3,h4,h5,h6 {
    // color: rgb(92, 81, 81);
    color: whitesmoke
}

body {
    font-family: 'Big Shoulders Stencil Text', cursive;
    background-color: rgb(63, 62, 62);
}

h1{
    font-size: 27px;
}

h2{
    font-size: 24px;
}

h3 {
    font-size: 20px;
}

h4{
    font-size: 15px;
}

h5 {
    font-size: 13px;
}

@import "./player";
@import "./song";
@import "./library"