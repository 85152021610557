.song-container {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img{
        width: 20%;
        border-radius: 10%;
      
    }
    h2 {
        padding: 3rem 1rem 0rem 1rem;
        text-align: center;
    }
    h4 {
        text-align: center;
    }
}


@media screen and (max-width: 768px) {
    .song-container {
        min-height: 30vh;
        img{
        margin: 50px;
        border-radius: 50%;
        -webkit-border-radius: 50%; 
        box-shadow: 0 0 0 70px black;
        animation-name: spin;
        animation-duration: 20000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        width: 50%
        }
    }
}


@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}