.library {
position:fixed;
top: 0;
left: 0;
width: 20rem;
Margin-top: 60px;
height: 100%;
background: rgba(0, 0, 0, 0.00);
opacity: 1;
overflow: scroll;
// box-shadow: 2px 2px 50px rgb(204, 204, 204);
box-shadow: 2px 2px 50px rgb(82, 80, 80);

&:hover{
    opacity: 1;
}
h1 {
    padding: 15px;
}

transform: translateX(-100%);
transition: ease-in-out 2s;
transition: all 0.5s ease;
opacity: 0%;
}

.LibraryMenu{
    display: flex;
    padding: 10px;
    transition: ease 1s;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: 10px;
    &:hover{
        opacity: 1
    }
}

.menuSVG{
    display: flex;
    width: 50px;
    text-align: center;
    justify-content: center;
    align-items: stretch;
}


.songslist {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 5px;
    cursor: pointer;
    img {
        height: 80px; 
    }
    &:hover{
        // background: rgb(184, 134, 241);
        background: rgb(79, 78, 80);
    }
    h3, h5{
        // color: black;
        color: rgb(141, 137, 137);
        padding: 3px 10px;
    }
}

* {
    scrollbar-width: thin;
    scrollbar-color: rgba(155,155,155,0.5)
}
*::-webkit-scrollbar {
    width: 5px
}
*::-webkit-scrollbar-track{
    background: transparent
}

*::-webkit-scrollbar-thumb{
    background-color: rgba(155,155,155,0.5);
    border-radius: 20px;
    border: transparent
}

.selected{
    background: rgb(50, 54, 61);
    
    // background: rgb(144, 182, 252);
}

.activeLibrary{
    transform: translateX(0);
    opacity: 1;
}

@media screen and (max-width: 768px) {
    .songList{
        z-index: 10
    }
    .library {
        width: 100%;
        background: rgb(63, 62, 62);
        box-shadow: none;
    }
}